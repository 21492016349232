import * as React from "react";
import { RecommendationsOverlayProps } from "./typings";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { RecommendationsOverlayModule } from "@shared-ui/retail-recommendations-overlay-module";
import { checkForBots } from "src/components/utility/BotsUtils";

export const RecommendationsOverlay = withStores("context")(
  observer((props: RecommendationsOverlayProps) => {
    const { templateComponent, context } = props;

    const isDesktop = context.deviceInformation?.type === "DESKTOP";

    const hasBots = checkForBots(context);

    if (hasBots) {
      return null;
    }

    const {
      cardContentSize,
      inputContext,
      offeringType,
      strategy,
      entity,
      placementId,
      expiringHours = 8,
      timeoutInMilliSeconds = 15000,
      timeoutInMilliSecondsMobile = 30000,
      isMouseLeaveEvent = true,
      overlayLocalStorageKeyName,
      pageId,
      cardTheme,
      priceStrategy,
      configurationIdentifier,
      containerType,
      isHidden = false,
    } = templateComponent.config;

    const timeout = isDesktop ? timeoutInMilliSeconds : timeoutInMilliSecondsMobile;
    const mouseLeaveEvent = isDesktop ? isMouseLeaveEvent : false;

    const data = {
      contentSize: cardContentSize,
      offeringType,
      strategy,
      input: inputContext,
      placementId,
      recommendationContext: {
        pageId: pageId,
        outputLineOfBusiness: entity,
        lodging: { propertyId: String(context.searchContext.location.id) },
        destination: { placeId: { id: String(context.searchContext.location.parent.id) } },
      },
      productId: String(context.searchContext.location.id),
      placeId: String(context.searchContext.location.parent.id),
      containerType,
      priceStrategy,
      configurationIdentifier,
    };

    return (
      <RecommendationsOverlayModule
        inputs={data}
        expiringHours={expiringHours}
        timeoutInMilliSeconds={timeout}
        isMouseLeaveEvent={mouseLeaveEvent}
        overlayLocalStorageKeyName={overlayLocalStorageKeyName}
        skipSsr={true}
        cardTheme={cardTheme}
        isHidden={isHidden}
      />
    );
  })
);

export default RecommendationsOverlay;
